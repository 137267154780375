import React from "react";



const MainDisplay = () => {

  return (
    <React.Fragment>
    
 
    </React.Fragment>
  );
};

export default MainDisplay;
